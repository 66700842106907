import React, {useCallback, useEffect, useState} from "react"
import {Accordion, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap"
import {useNavigate, useParams} from "react-router-dom";
import usePlatform from "../../hooks/usePlatform";
import VideoTimelineViewer from "../../components/VideoTimelineViewer";

const FeedVideoScreen = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { getFeedVideoV1, updateFeedVideoV1, deleteFeedVideoV1, getCreatorFeedVideoV1 } = usePlatform()
  const [state, setState] = useState({ loading: true, error: false, data: null })
  // const [videoUrl, setVideoUrl] = useState("")
  const [creatorFeedVideoId, setCreatorFeedVideoId] = useState("")

  const onGet = useCallback(() => {
    getFeedVideoV1(id).then(json => {
      setState({ loading: false, error: false, data: json})
      // setVideoUrl(json.video?.url)
    }).catch(error => {
      console.error(error)
      setState({ loading: false, error: true, data: null })
    })
  }, [id, getFeedVideoV1])

  const onUpdate = () => {
    updateFeedVideoV1(id, state.data).then(json => {
      // console.log(json)
    }).catch(error => {
      console.error(error)
    })
  }

  const onDelete = () => {
    deleteFeedVideoV1(id).then(() => {
      navigate(`/admin-feed-videos`)
    }).catch(error => {
      console.error(error)
    })
  }

  useEffect(() => {
    onGet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLoadCreatorFeedVideo = () => {
    if (!creatorFeedVideoId) {
      console.error("Cannot load video without ID")
      return
    }

    getCreatorFeedVideoV1(creatorFeedVideoId).then(json => {
      setState(prev => ({
        loading: false, error: false, data: {
          ...json,

          // do not overwrite these
          id,
          publishedTimestamp: prev.publishedTimestamp,
          createdTimestamp: prev.createdTimestamp,

          // set this
          creatorFeedVideoId,

          // reset this
          status: 'DRAFT',
        }
      }))
    }).catch(error => {
      console.error(error)
    })
  }

  const setField = (field, value) => {
    setState((prev) => ({ ...prev, data: { ...prev.data, [field]: value } }))
  }

  return (
    <Container className="mt-3">
      {state.error === true && (
        <p>There was an error</p>
      )}

      {state.loading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      )}

      {!state.loading && !state.error && (
        <>
          <Row className="mb-3">
            <Col></Col>

            <Col>
              <div className="d-flex justify-content-end">
                <Button variant="success" className="me-2" onClick={onUpdate}>
                  Update
                </Button>

                <Button variant="danger" className="me-2" onClick={onDelete}>
                  Delete
                </Button>
              </div>
            </Col>
          </Row>

          <Accordion className="mb-3">
            <Accordion.Item eventKey={"1"}>
              <Accordion.Header>Creator Feed Video Source</Accordion.Header>
              <Accordion.Body>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Control
                      className="mb-2"
                      placeholder="Creator Feed Video ID"
                      value={creatorFeedVideoId}
                      onChange={(event) => { setCreatorFeedVideoId(event.target.value) }}
                    />
                  </Col>

                  <Col xs="auto">
                    <Button variant="success" className="mb-2" onClick={onLoadCreatorFeedVideo}>
                      Load Creator Feed Video
                    </Button>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Video Configuration</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control value={state.data.title} readOnly/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Video URL</Form.Label>
                      <Form.Control value={state.data.video?.url} readOnly/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Video Duration</Form.Label>
                      <Form.Control value={state.data.video?.duration} readOnly/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Orientation</Form.Label>
                      <Form.Select value={state.data.viewAsWhite ? "white" : "black"} disabled>
                        <option value="white">View as White</option>
                        <option value="black">View as Black</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" rows={5} value={state.data.description} readOnly />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Thumbnail URL</Form.Label>
                      <Form.Control value={state.data.thumbnailUrl} readOnly />
                    </Form.Group>

                    {state.data.thumbnailUrl ? (
                      <img className="img-fluid" src={state.data.thumbnailUrl} alt="" />
                    ) : (
                      <p>No thumbnail to load.</p>
                    )}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey={"3"}>
              <Accordion.Header>Publishing Details</Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    onChange={(event) => { setField("status", event.target.value) } }
                    value={state.data.status}
                  >
                    <option value="">-</option>
                    <option value="DRAFT">Draft</option>
                    <option value="SCHEDULED">Scheduled</option>
                    <option value="PUBLISHED">Published</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Created Timestamp</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={state.data.createdTimestamp}
                    onChange={(e) => {
                      setField("createdTimestamp", e.target.value)
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Published Timestamp</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={state.data.publishedTimestamp}
                    onChange={(e) => {
                      setField("publishedTimestamp", e.target.value)
                    }}
                  />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <VideoTimelineViewer
            originalTimeline={state.data.video?.timeline ?? []}
            videoUrl={state.data.url}
            viewAsWhite={state.data.viewAsWhite}
          />
        </>
      )}
    </Container>
  );
}

export default FeedVideoScreen
