import React, {useCallback, useEffect, useState} from "react"
import {Button, ButtonGroup, Col, Container, Form, Row, Spinner, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import usePlatform from "../../hooks/usePlatform";

const FeedVideosScreen = () => {
  const { getFeedVideosV1, postFeedVideoV1 } = usePlatform()
  const navigate = useNavigate()
  const [data, setData] = useState({ loading: true, error: false, data: null })
  const [status, setStatus] = useState("PUBLISHED")
  const [nextId, setNextId] = useState(1) // todo set this to the next value after `fetchVideos`

  const fetchVideos = useCallback(() => {
    setData({loading: true, error: false, data: null})
    getFeedVideosV1(status).then(json => {
      console.log(json)
      setData({loading: false, error: false, data: json})
    }).catch(error => {
      setData({loading: false, error: true, data: null})
    })
  }, [getFeedVideosV1, status])

  useEffect(() => {
    fetchVideos()
  }, [fetchVideos, status])

  const onNewFeedVideo = () => {
    postFeedVideoV1({ id: nextId }).then(json => {
      navigate(`/admin-feed-videos/${json.id}`)
    }).catch(error => {
      console.error(error)
    })
  }

  const onOpenFeedVideo = (id) => {
    navigate(`/admin-feed-videos/${id}`)
  }

  return (
    <Container className="my-4">
      <div className="mb-3">
        <Row>
          <Col>
            <ButtonGroup className="me-2">
              <Button variant="secondary" disabled={status==="PUBLISHED"} onClick={() => {setStatus("PUBLISHED")}}>Published</Button>
              <Button variant="secondary" disabled={status==="SCHEDULED"} onClick={() => {setStatus("SCHEDULED")}}>Scheduled</Button>
              <Button variant="secondary" disabled={status==="DRAFT"} onClick={() => {setStatus("DRAFT")}}>Draft</Button>
            </ButtonGroup>
          </Col>

          <Col>
            <div className="d-flex justify-content-end">
              <Form.Group className="me-3">
                <Form.Control type="numbder" value={nextId} onChange={(e) => { setNextId(e.target.value) }} />
              </Form.Group>

              <Button variant="primary" onClick={onNewFeedVideo}>Create</Button>
            </div>
          </Col>
        </Row>
      </div>

      {data.loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      ) : data.error ? (
        <p>There was an error</p>
      ) : !data.data || data.data.length === 0 ? (
        <p>No data to display</p>
      ) : (
        <Table>
          <thead>
          <tr>
            <th>ID</th>
            <th>Created</th>
            <th>Title</th>
            <th>Creator</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {data.data.map(entry => (
            <tr key={entry.id}>
              <td>{entry.id}</td>
              <td>{entry.createdTimestamp}</td>
              <td>{entry.title}</td>
              <td>{entry.creator.firstName || "Unknown"} {entry.creator.surname || "Unknown"}</td>
              <td>
                <Button size="sm" variant="secondary" onClick={() => { onOpenFeedVideo(entry.id) }}>Edit</Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}

export default FeedVideosScreen
