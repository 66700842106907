import React, {useEffect, useState} from "react"
import {Container, Spinner} from "react-bootstrap"
import {useParams} from "react-router-dom";
import usePlatform from "../../hooks/usePlatform";

const UserScreen = () => {
  const { id } = useParams()
  const { getUserV1 } = usePlatform()
  const [data, setData] = useState({ loading: true, error: false, data: null })

  useEffect(() => {
    getUserV1(id).then(json => {
      setData({ loading: false, error: false, data: json })
    }).catch(error => {
      setData({ loading: false, error: true, data: null })
    })
  }, [id, getUserV1])

  return (
    <Container className="mt-4">
      {data.loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      ) : data.error ? (
        <p>There was an error</p>
      ) : (
        <pre>{JSON.stringify(data.data)}</pre>
      )}
    </Container>
  )
}

export default UserScreen
