import React, {useCallback, useEffect, useRef, useState} from "react"
import {Accordion, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap"
import {useParams} from "react-router-dom"
import {Chessboard} from "react-chessboard"
import usePlatform from "../../hooks/usePlatform"
import usePreviewVideoTimeline from "../../hooks/usePreviewVideoTimeline"

const AdminDailyPuzzleScreen = () => {
  const { date } = useParams()
  const { getAdminDailyPuzzleV1, getCreatorPuzzleVideoV1, putAdminDailyPuzzleV1 } = usePlatform()
  const [creatorPuzzleVideoId, setCreatorPuzzleVideoId] = useState("")
  const [state, setState] = useState({ loading: true, error: false, data: null })
  const {timelineIndex, updateVideoTimestamp, initTimeline } = usePreviewVideoTimeline()
  const videoRef = useRef()

  const fetchDailyPuzzle = useCallback(() => {
    setState({loading: true, error: false, data: null})
    getAdminDailyPuzzleV1(date).then(json => {
      setState({loading: false, error: false, data: {
          date: date,
          creator: json.creator,
          puzzle: json.puzzle,
          video: json.video,
      }})
      initTimeline(json.video.timeline)
    }).catch(error => {
      setState({loading: false, error: true, data: null})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdminDailyPuzzleV1, date])

  useEffect(() => {
    fetchDailyPuzzle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const previewAvailable = () => Object.keys(state.data.puzzle).length !== 0 && Object.keys(state.data.video).length !== 0

  const onUpdate = () => {
    putAdminDailyPuzzleV1(date, state.data).catch(error => {
      console.error(error)
    })
  }

  const onLoadCreatorPuzzleVideo = () => {
    getCreatorPuzzleVideoV1(creatorPuzzleVideoId).then(creatorPuzzleVideo => {
      setState(prev => ({ ...prev, data: {
        date: date,
        creator: creatorPuzzleVideo.creator,
        puzzle: creatorPuzzleVideo.puzzle,
        video: creatorPuzzleVideo.video,
      }}))
      initTimeline(creatorPuzzleVideo.video.timeline)
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <Container className="my-4">
      {state.loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      ) : state.error ? (
        <p>There was an error</p>
      ) : (
        <>
          <div className="mb-3 d-flex justify-content-end">
            <Button variant="success" className="me-2" onClick={onUpdate}>Update</Button>
          </div>

          <Accordion className="mb-3">
            <Accordion.Item eventKey={"-1"}>
              <Accordion.Header>Creator Puzzle Video Configuration</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <Form.Control
                      className="mb-2"
                      placeholder="Creator Puzzle Video ID"
                      value={creatorPuzzleVideoId}
                      onChange={(event) => { setCreatorPuzzleVideoId(event.target.value) }}
                    />
                  </Col>

                  <Col xs="auto">
                    <Button variant="success" className="mb-2" onClick={onLoadCreatorPuzzleVideo}>Load Creator Puzzle Video</Button>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {previewAvailable() ? (
            <Row>
              <Col xs={5}>
                <div className="mb-3">
                  <p>Puzzle Position</p>
                  <Chessboard
                    id="yeschess"
                    position={state.data.puzzle.fen}
                    boardOrientation={state.data.puzzle.fen.split(' ')[1] === 'w' ? 'black' : 'white'}
                    customArrows={[[
                      state.data.puzzle.moves[0].substring(0, 2),
                      state.data.puzzle.moves[0].substring(2, 4),
                    ]]}
                  />
                </div>

                <Form.Group className="mb-3">
                  <Form.Label>Puzzle ID</Form.Label>
                  <Form.Control value={state.data.puzzle.id} readOnly/>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>FEN</Form.Label>
                  <Form.Control value={state.data.puzzle.fen} readOnly/>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Difficulty</Form.Label>
                  <Form.Control value={state.data.puzzle.difficulty} readOnly/>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Moves</Form.Label>
                  <Form.Control value={state.data.puzzle.moves.join(" ")} readOnly/>
                </Form.Group>
              </Col>

              <Col xs={7}>
                <Row>
                  <Col></Col>
                  <Col>
                    <video
                      ref={videoRef}
                      width='100%'
                      controls
                      onTimeUpdate={(event) => { updateVideoTimestamp(event.target.currentTime * 1000) }}
                    >
                      <source src={state.data.video.url} />
                      Your browser does not support the video tag.
                    </video>
                  </Col>
                </Row>

                {timelineIndex}

                {state.data.video.timeline && state.data.video.timeline.length > 0 ? (
                  <Chessboard
                    id="yeschess"
                    position={state.data.video.timeline[timelineIndex].fen}
                    customArrows={state.data.video.timeline[timelineIndex].arrows}
                    boardOrientation={state.data.video.viewAsWhite ? 'white' : 'black'}
                  />
                ) : (
                  <>
                    <Chessboard />
                    <p style={{ fontStyle: 'italic' }}>NOTE: No timeline to play through</p>
                  </>
                )}
              </Col>
            </Row>
          ) : (
            <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Unable to preview daily puzzle without the puzzle and video</p>
          )}
        </>
      )}
    </Container>
  )
}

export default AdminDailyPuzzleScreen
