import {useEffect, useRef} from "react"
import {Button, ButtonGroup, Col, Row, Table} from "react-bootstrap"
import {Chessboard} from "react-chessboard"
import usePreviewVideoTimeline from "../hooks/usePreviewVideoTimeline";

const VideoTimelineViewer = ({ originalTimeline, videoUrl, viewAsWhite }) => {
  const videoRef = useRef()
  const {
    timeline,
    timelineIndex,
    setTimelineIndex,
    initTimeline,
    goBackwards,
    goForwards,
  } = usePreviewVideoTimeline()

  useEffect(() => {
    initTimeline(originalTimeline)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalTimeline])

  useEffect(() => {
    videoRef.current?.load()
  }, [videoUrl])

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Row>
            <Col xs="4">
              <video
                ref={videoRef}
                width='100%'
                controls
              >
                <source src={videoUrl} />
                Your browser does not support the video tag.
              </video>

              {timeline && timeline.length > 0 ? (
                <Chessboard
                  id="yeschess"
                  position={timeline[timelineIndex].fen}
                  customArrows={timeline[timelineIndex].arrows}
                  boardOrientation={viewAsWhite ? 'white' : 'black'}
                />
              ) :
                <>
                  <Chessboard
                    id="yeschess"
                    boardOrientation={viewAsWhite ? 'white' : 'black'}
                  />
                </>
              }

              <ButtonGroup className="my-3">
                <Button variant="secondary" onClick={goBackwards}>&larr;</Button>
                <Button variant="secondary" onClick={goForwards}>&rarr;</Button>
              </ButtonGroup>
            </Col>

            <Col xs="8">
              <div style={{ height: "750px", width: '100%', overflowY: "scroll" }} className="mb-3">
                <Table size="sm">
                  <thead>
                  <tr>
                    <th>Ofs</th>
                    <th>FEN</th>
                    <th>Arrows</th>
                    <th>Squares</th>
                  </tr>
                  </thead>
                  <tbody>
                  {timeline && timeline.map((entry, index) => (
                    <tr
                      key={index}
                      className={index === timelineIndex ? "table-success" : ""}
                      onClick={() => { setTimelineIndex(index) }}
                    >
                      <td>{entry.ofs}</td>
                      <td><small>{entry.fen}</small></td>
                      <td>{entry.arrows.length === 0 ? "-" : entry.arrows.join(", ")}</td>
                      <td>{entry.squares.length === 0 ? "-" : entry.squares.join(", ")}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )

}

export default VideoTimelineViewer
