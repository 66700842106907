import React, {useCallback, useEffect, useState} from "react"
import {Button, Col, Container, Form, Row, Spinner, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import usePlatform from "../../hooks/usePlatform";

const AdminDailyPuzzlesScreen = () => {
  const today = new Date().toISOString().split('T')[0] // yyyy-mm-dd

  const { getAdminDailyPuzzlesV1, postAdminDailyPuzzleV1 } = usePlatform()
  const navigate = useNavigate()
  const [data, setData] = useState({ loading: true, error: false, data: null })
  const [year, setYear] = useState(today.split('-')[0])
  const [month, setMonth] = useState(today.split('-')[1])
  const [createPuzzleDate, setCreatePuzzleDate] = useState(today)

  const fetchDailyPuzzles = useCallback(() => {
    setData({loading: true, error: false, data: null})
    getAdminDailyPuzzlesV1(year, month).then(json => {
      console.log(json)
      setData({loading: false, error: false, data: json})
    }).catch(error => {
      setData({loading: false, error: true, data: null})
    })
  }, [getAdminDailyPuzzlesV1, year, month])

  useEffect(() => {
    fetchDailyPuzzles()
  }, [fetchDailyPuzzles])

  const onNewDailyPuzzle = () => {
    postAdminDailyPuzzleV1({
      date: createPuzzleDate,
    }).then(json => {
      navigate(`/admin-daily-puzzles/${createPuzzleDate}`)
    }).catch(error => {
      console.error(error)
    })
  }

  const onOpenDailyPuzzle = (date) => {
    navigate(`/admin-daily-puzzles/${date}`)
  }

  return (
    <Container className="my-4">
      <div className="mb-3">
        <Row>
          <Col xs={2}>
            <Form.Group className="mb-3">
              <Form.Select value={year} onChange={(event) => { setYear(event.target.value) }}>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={2}>
            <Form.Group className="mb-3">
              <Form.Select value={month} onChange={(event) => { setMonth(event.target.value) }}>
                <option value="12">December</option>
                <option value="11">November</option>
                <option value="10">October</option>
                <option value="09">September</option>
                <option value="08">August</option>
                <option value="07">July</option>
                <option value="06">June</option>
                <option value="05">May</option>
                <option value="04">April</option>
                <option value="03">March</option>
                <option value="02">February</option>
                <option value="01">January</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col>
            <div className="d-flex justify-content-end">
              <Form.Group className="me-3">
                <Form.Control type="date" value={createPuzzleDate} onChange={(e) => { setCreatePuzzleDate(e.target.value) }} />
              </Form.Group>

              <Button className="pull-right" variant="primary" onClick={onNewDailyPuzzle}>Create</Button>
            </div>
          </Col>
        </Row>
      </div>

      {data.loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      ) : data.error ? (
        <p>There was an error</p>
      ) : (
        <Table>
          <thead>
          <tr>
            <th>Date</th>
            <th>Puzzle ID</th>
            <th>Difficulty</th>
            <th>Video</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {!data.data || data.data.length === 0 ? (
            <tr>
              <td colSpan="5" style={{ textAlign: 'center' }}>No data to display</td>
            </tr>
          ) : data.data.map(entry => (
            <tr key={entry.date}>
              <td>{entry.date}</td>
              <td>{entry.puzzle?.id}</td>
              <td>{entry.puzzle?.difficulty}</td>
              <td>{entry.video?.id}</td>
              <td>
                <Button size="sm" variant="secondary" onClick={() => { onOpenDailyPuzzle(entry.date) }}>Edit</Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}

export default AdminDailyPuzzlesScreen
