import {useEffect, useState} from "react"
import {Button, Col, Container, Form, Row} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import useFirebaseAuth from "../../hooks/useFirebaseAuth";
import logo from '../../assets/logo.png';

const LogInScreen = () => {
  const { logIn, user } = useFirebaseAuth()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (user) {
      navigate("/admin-daily-puzzles")
    }
  }, [user, navigate])

  const onLogin = async () => {
    logIn(email, password).then(() => {
        navigate("/admin-daily-puzzles")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      })
  }

  return (
    <Container className="mt-3">
      <Row className="justify-content-md-center mx-5">
        <Col lg="4">
          <img className="d-block mx-auto my-4 img-fluid" src={logo} alt="Logo" />

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control onChange={(event) => { setEmail(event.target.value) }} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" onChange={(event) => { setPassword(event.target.value) }} />
          </Form.Group>

          <Button variant="primary" onClick={onLogin}>Login</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default LogInScreen
