import useFirebaseAuth from "./useFirebaseAuth";
import {useCallback} from "react";

const baseUrl = "https://apis.play.yeschess.app"

const usePlatform = () => {
  const { user } = useFirebaseAuth()

  const getAdminDailyPuzzlesV1 = useCallback(async (year, month) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/admin/daily-puzzles?year=${year}&month=${month}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getAdminDailyPuzzleV1 = useCallback(async (date) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/admin/daily-puzzles/${date}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  // todo support sending puzzle/video with POST
  const postAdminDailyPuzzleV1 = useCallback(async ({ date }) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/admin/daily-puzzles`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            date,
          }),
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const putAdminDailyPuzzleV1 = useCallback(async (date, dailyPuzzle) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/admin/daily-puzzles/${date}`, {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(dailyPuzzle),
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getUsersV1 = useCallback(async () => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/users`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getUserV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/users/${id}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const postFeedVideoV1 = useCallback(async ({ id }) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/feed-videos`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({ id }),
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getFeedVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/feed-videos/${id}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getFeedVideosV1 = useCallback(async (status) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/feed-videos?status=${status}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const deleteFeedVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/feed-videos/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
        })
      })
  }, [user])

  const updateFeedVideoV1 = useCallback(async (id, video) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/feed-videos/${id}`, {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(video)
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getCreatorPuzzleVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-puzzle-videos/${id}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getCreatorFeedVideoV1 = useCallback(async (id) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/creator-feed-videos/${id}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  const getRandomPuzzleV1 = useCallback(async (difficulty) => {
    if (!user) throw new Error("Unable to usePlatform without a user")
    return user.getIdToken()
      .then(token => {
        return fetch(`${baseUrl}/v1/puzzles/random?difficulty=${difficulty}`, {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          })
        })
      })
      .then(response => {
        return response.json()
      })
  }, [user])

  return {
    getAdminDailyPuzzlesV1,
    getAdminDailyPuzzleV1,
    postAdminDailyPuzzleV1,
    putAdminDailyPuzzleV1,
    getUserV1,
    getUsersV1,
    postFeedVideoV1,
    getFeedVideoV1,
    getFeedVideosV1,
    updateFeedVideoV1,
    deleteFeedVideoV1,
    getCreatorFeedVideoV1,
    getCreatorPuzzleVideoV1,
    getRandomPuzzleV1,
  }
}

export default usePlatform
