import {Button, Container, Nav, Navbar} from "react-bootstrap"
import {Link, useNavigate} from "react-router-dom"
import useFirebaseAuth from "../hooks/useFirebaseAuth";
import logo from '../assets/logo.png';

const AppNavBar = () => {
  const { logOut } = useFirebaseAuth()
  const navigate = useNavigate();

  const onSignOut = () => {
    logOut().then(() => {
      navigate("/")
    }).catch((error) => {
      console.error(error)
    })
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} height={30} alt="Logo" style={{ marginBottom: "8px" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/admin-daily-puzzles">Daily Puzzles</Nav.Link>
            <Nav.Link as={Link} to="/admin-feed-videos">Feed Videos</Nav.Link>
            <Nav.Link as={Link} to="/users">Users</Nav.Link>
          </Nav>
          <Nav>
            <Button variant='link' onClick={onSignOut}>Sign out</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AppNavBar
