import {useEffect, useState} from "react"
import { initializeApp } from 'firebase/app'
import {getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDpwCrIyqJN5XQEf3AOduJ86dykdRFIRjk",
  authDomain: "yes-chess-play.firebaseapp.com",
  projectId: "yes-chess-play",
  storageBucket: "yes-chess-play.appspot.com",
  messagingSenderId: "205158198315",
  appId: "1:205158198315:web:9d6b9d5fd7544ff9b48e6e",
  measurementId: "G-E86HPC975L"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const useFirebaseAuth = () => {
  const [initialising, setInitialising] = useState(true)
  const [user, setUser] = useState(auth.currentUser)

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setUser(user)
      if (initialising) {
        setInitialising(false)
      }
    }) // `return` to unsubscribe on unmount
  }, [initialising])

  const logOut = async () => {
    return signOut(auth)
  }

  const logIn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  return {
    initialising,
    user,
    logIn,
    logOut,
  }
}

export default useFirebaseAuth
