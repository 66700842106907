import {useEffect, useState} from "react"
import {Button, Container, Spinner, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import usePlatform from "../../hooks/usePlatform";

const UsersScreen = () => {
  const { getUsersV1 } = usePlatform()
  const navigate = useNavigate()
  const [data, setData] = useState({ loading: true, error: false, data: null })

  useEffect(() => {
    getUsersV1().then(json => {
      console.log(json)
      setData({loading: false, error: false, data: json})
    }).catch(error => {
      setData({loading: false, error: true, data: null})
    })
  }, [getUsersV1])

  const onOpenUser = (id) => {
    navigate(`/users/${id}`)
  }

  return (
    <Container className="my-4">
      {data.loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>
      ) : data.error ? (
        <p>There was an error</p>
      ) : !data.data || data.data.length === 0 ? (
        <p>No data to display</p>
      ) : (
        <>
          <Table>
            <thead>
            <tr>
              <th>ID</th>
              <th>Created</th>
              <th>Puzzles</th>
              <th>Success Rate</th>
              <th>Rating</th>
            </tr>
            </thead>
            <tbody>
            {data.data.map(entry => (
              <tr key={entry.id}>
                <td>{entry.id}</td>
                <td>{entry.created}</td>
                <td>{entry.overallPuzzleRating.solves}/{entry.overallPuzzleRating.attempts}</td>
                <td>
                  {entry.overallPuzzleRating.attempts > 0 ? (
                    <>
                      {Math.round(entry.overallPuzzleRating.solves/entry.overallPuzzleRating.attempts*100)}%
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>{entry.overallPuzzleRating.value}</td>
                <td>
                  <Button size="sm" variant="secondary" onClick={() => { onOpenUser(entry.id) }}>Edit</Button>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  )
}

export default UsersScreen
