import {createContext} from "react";
import {Spinner} from "react-bootstrap";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import AppNavBar from "./components/AppNavBar"
import LogInScreen from "./screens/LogIn/LogInScreen"
import FeedVideosScreen from "./screens/FeedVideos/FeedVideosScreen";
import FeedVideoScreen from "./screens/FeedVideos/FeedVideoScreen";
import UsersScreen from "./screens/Users/UsersScreen";
import UserScreen from "./screens/Users/UserScreen";
import AdminDailyPuzzlesScreen from "./screens/AdminDailyPuzzles/AdminDailyPuzzlesScreen";
import AdminDailyPuzzleScreen from "./screens/AdminDailyPuzzles/AdminDailyPuzzleScreen";
import useFirebaseAuth from "./hooks/useFirebaseAuth";

const authContext = createContext()

const AuthProvider = ({ children }) => {
  const auth = useFirebaseAuth()
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

const App = () => {
  const { user, initialising } = useFirebaseAuth()
  if (initialising) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="primary" />
      </div>
    )
  }

  return (
    <AuthProvider>
      <Router>
        {user !== null && (
          <AppNavBar />
        )}
        <Routes>
          <Route exact path="/" element={<LogInScreen />} />
          <Route exact path="/admin-daily-puzzles" element={<AdminDailyPuzzlesScreen />} />
          <Route exact path="/admin-daily-puzzles/:date" element={<AdminDailyPuzzleScreen />} />
          <Route exact path="/admin-feed-videos" element={<FeedVideosScreen />} />
          <Route exact path="/admin-feed-videos/:id" element={<FeedVideoScreen />} />
          <Route exact path="/users" element={<UsersScreen />} />
          <Route exact path="/users/:id" element={<UserScreen />} />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App;
