import {useState} from "react";

const usePreviewVideoTimeline = () => {
  const [timeline, setTimeline] = useState(null)
  const [timelineIndex, setTimelineIndex] = useState(0)

  const initTimeline = (timeline) => {
    setTimeline(timeline)
    setTimelineIndex(0)
  }

  const updateVideoTimestamp = (seconds) => {
    const before = timeline
      .map((entry, index) => ({ ...entry, index }))
      .filter(entry => entry.ofs !== null && entry.ofs < seconds)

    if (before.length > 0) {
      const last = before[before.length - 1]
      setTimelineIndex(last.index)
    }
  }

  // todo maybe "onClickTimeline(index)" // change video timestamp and load chessboard

  return {
    timeline,
    timelineIndex,
    setTimelineIndex,
    initTimeline,
    updateVideoTimestamp,
  }
}

export default usePreviewVideoTimeline